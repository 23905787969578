define("sc-portal/pods/components/matter/report-browser/download-document/component", ["exports", "sc-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    metrics: Ember.inject.service(),
    fileNameFormat: null,
    suggestedFileName: "download.php",
    tagName: "",
    actions: {
      clickDownload: function clickDownload() {
        var _this = this;

        Ember.get(this, "metrics").trackEvent({
          category: "Documents",
          action: "download",
          label: this.get("id")
        });
        var fileNameFormat = this.get("fileNameFormat");
        this.ajax.post("/users/generate-nonce", {
          contentType: "application/json"
        }).then(function (response) {
          var params = new URLSearchParams({
            subject_id: _this.get("subjectId"),
            file_name_format: !Ember.isEmpty(fileNameFormat) ? fileNameFormat : '',
            token: response.token
          }).toString();
          var url = "".concat(_environment.default.APP.documentDownloadURL).concat(_this.get('id'), "/download?").concat(params);
          var $downloadLink = document.createElement('a');
          $downloadLink.setAttribute('target', "_blank");
          $downloadLink.setAttribute('href', url); // firefox hack doesn`t allow click trigger on href

          var ev = document.createEvent('MouseEvents');
          ev.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null); // Fire event

          $downloadLink.dispatchEvent(ev);
        }).catch(function (error) {
          console.error("Error generating download nonce:", error); // Handle error - display message to user, etc.

          _this.get("notify").error("Error initiating download. Please try again.");
        });
        return false;
      }
    }
  });

  _exports.default = _default;
});