define("sc-portal/pods/components/matter/report-browser/download-document/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "scw2lohe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"href\",\"#\"],[12,\"download\",[23,\"suggestedFilename\"]],[11,\"rel\",\"noopener noreferrer\"],[11,\"class\",\"link\"],[11,\"style\",\"padding: 1px;\"],[9],[0,\"\\n  \"],[1,[29,\"fa-icon\",[\"download\"],null],false],[0,\"\\n  Download\\n  \"],[1,[29,\"bs-tooltip\",null,[[\"title\"],[\"Download document\"]]],false],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"clickDownload\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/components/matter/report-browser/download-document/template.hbs"
    }
  });

  _exports.default = _default;
});